import "./Services.scss";

import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <section className="services">
      <article className="services__container">
        <article className="item__top">
          <article className="item__content">
            <h2>موقعنا في مدينة قنا الجديدة</h2>
            <p>
              يحتل موقعنا في مدينة قنا الجديدة موقع استراتيجي مميز، وذلك لوقوعه
              علي طريق البحر الاحمر ، والذى يربط صعيد مصر ومدن البحر الاحمر.
              الموقع الحيوي الذي يتمتع به المشروع لوقوعه بالقرب من العديد من
              المشروعات الشهيرة بالمنطقة .الموقع على بعد دقائق معدودة من جامعة
              جنوب الوادي ومستشفيات الجامعة . ينفرد بموقعه الممتاز من أشهر
              المراكز التجارية والمولات في المدينة.
            </p>
          </article>
          <article className="item__img" direction="left">
            <Slide triggerOnce>
              <img
                src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705526286/Eastern/ubhs6rp5vvpkdrgt1pki.png"
                alt=""
              />
            </Slide>
          </article>
        </article>
        <article className="item__bottom">
          <article className="item__img">
            <Slide triggerOnce direction="right">
              <img
                src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705526269/Eastern/vtfwvjfiueytkfezoxp3.png"
                alt=""
              />
            </Slide>
          </article>
          <article className="item__content">
            <h2>الخدمات و المميزات</h2>
            <ul>
              <li>المساحات الخضراء</li>
              <li>مطاعم وكافيهات</li>
              <li>نادي صحي علي أعلي مستوي</li>
              <li>موقف سيارات خاص</li>
              <li>نادي اجتماعي راقي</li>
              <li>مناطق تجارية راقية</li>
              <li>حدائق مخصصة للاطفال</li>
              <li>حمامات السباحة</li>
              <li>خدمات صحية وعلاجية</li>
              <li>صالة جيم و صبا</li>
              <li>فنادق علي أعلي مستوي</li>
              <li>خدمات حراسة علي مدار 24 ساعة</li>
            </ul>
          </article>
        </article>
      </article>
    </section>
  );
};

export default Services;
