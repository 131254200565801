import "./Home.scss";
import About from "../Components/About/About";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Information from "../Components/Information/Information";
import Landing from "../Components/Landing/Landing";
import Portfolio from "../Components/Portfolio/Portfolio";
import Price from "../Components/Price/Price";
import Services from "../Components/Services/Services";

const Home = () => {
  return (
    <main>
      <Header />
      <Landing />
      <About />
      <Portfolio />
      <Services />
      <Price />
      <Information />
      <Footer />
      <article className="phone">
        <p>01066498555</p>
        <img
          src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705697609/x18xb8wztseqvhbo3h7q.png"
          alt=""
        />
      </article>
      <article className="whatsapp">
        <img
          src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705526259/Eastern/ph8o4xt7irndxyydrdzy.png"
          alt=""
        />
      </article>
    </main>
  );
};

export default Home;
