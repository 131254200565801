import "./Portfolio.scss";

const Portfolio = () => {
  return (
    <section className="portfolio">
      <article className="portfolio__container">
        <article className="special-heading">
          <h2>الخدمات الخاصة بالمشروع</h2>
        </article>
        <article className="image-container">
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-standalone-1024x576.jpg"
              alt=""
            />
            <article className="caption">
              <h3>المساحات الخضراء في كل مكان بالكمبوند</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-twin-1024x666.jpg"
              alt=""
            />
            <article className="caption">
              <h3>أماكن مخصصة لممارسة الرياضة</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/WhatsApp-Image-2023-10-04-at-16.13.31_d52aa42d.jpg"
              alt=""
            />
            <article className="caption">
              <h3>المسطحات المائية والبحيرات الصناعية</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/s2-1.jpg"
              alt=""
            />
            <article className="caption">
              <h3>نادي صحي مجهز</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/s3-1.jpg"
              alt=""
            />
            <article className="caption">
              <h3>جراجات للسيارات الخاصة فقط</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-apts-1024x666.jpg"
              alt=""
            />
            <article className="caption">
              <h3>يوجد الكثير من الخدمات التعليمية</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-standalone-1024x576.jpg"
              alt=""
            />
            <article className="caption">
              <h3>عدد كبير من المطاعم والكافيهات</h3>
            </article>
          </article>

          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-town-1024x666.jpg"
              alt=""
            />
            <article className="caption">
              <h3>مناطق لإقامة حفلات الشواء والحفلات الخاصة</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-twin-1024x666.jpg"
              alt=""
            />
            <article className="caption">
              <h3>نادي رياضي كبير</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/s6-1024x666.jpg"
              alt=""
            />
            <article className="caption">
              <h3>مراكز تجارية عالمية للتسوق</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/s1-1024x666.jpg"
              alt=""
            />
            <article className="caption">
              <h3>مشروع متكامل الخدمات والمرافق</h3>
            </article>
          </article>
          <article className="box">
            <img
              src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/WhatsApp-Image-2023-10-04-at-16.13.31_d52aa42d.jpg"
              alt=""
            />
            <article className="caption">
              <h3>منطقة ترفيهية كبيرة للأطفال</h3>
            </article>
          </article>
        </article>
      </article>
    </section>
  );
};

export default Portfolio;
