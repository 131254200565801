import "./Header.scss";
import { Link } from "react-scroll";
import { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RoofingIcon from "@mui/icons-material/Roofing";
import LocationIcon from "@mui/icons-material/PinDrop";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import MenuIcon from "@mui/icons-material/Menu";
import ProgressBar from "../ProgressBar/ProgressBar";

const Header = () => {
  const [activeLink, setActiveLink] = useState(0);

  return (
    <header>
      <section className="header__container">
        {/* <article className="logo">
          <img
            src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705601166/Eastern/ps2d0vmhnmowhdbsxhsy.png"
            alt=""
          />
        </article> */}
        <article className="logo">
          <article className="logo__text">
            <span className="f-span">EASTERN</span>
            <span className="s-span">REAL ESTATE</span>
          </article>
          <img
            src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705601185/Eastern/brj80dqzlok4kz4jsgt0.png"
            alt=""
          />
        </article>

        <article className="menu">
          <Link
            to="landing"
            className={activeLink === 0 ? "menu__item active" : "menu__item"}
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(0)}
          >
            الرئيسية
          </Link>
          <Link
            to="about"
            className={activeLink === 1 ? "menu__item active" : "menu__item"}
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(1)}
          >
            من نحن
          </Link>
          <Link
            to="portfolio"
            className={activeLink === 2 ? "menu__item active" : "menu__item"}
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(2)}
          >
            الخدمات الخاصة بالمشروع
          </Link>
          <Link
            to="services"
            className={activeLink === 3 ? "menu__item active" : "menu__item"}
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(3)}
          >
            موقع المشروع
          </Link>
          <Link
            to="price"
            className={activeLink === 4 ? "menu__item active" : "menu__item"}
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(4)}
          >
            الوحدات
          </Link>
          <Link
            to="information"
            className={activeLink === 5 ? "menu__item active" : "menu__item"}
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(5)}
          >
            لماذا نحن
          </Link>
          <Link className="join" to="">
            تواصل معانا
          </Link>
        </article>
      </section>

      {/* Mobile Only ↓ */}
      <section className="header__contanier-mobiles">
        <article className="phone__icon">
          <MenuIcon className="icon" />
        </article>

        <article className="phone__logo">
          <article className="phone__icon-wrapper">
            <span className="f-span">Eastern</span>
            <span className="s-span">Real Estate</span>
          </article>
          <img
            src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705601185/Eastern/brj80dqzlok4kz4jsgt0.png"
            alt=""
          />
        </article>

        <article className="menu__items">
          <Link
            className="phone__item"
            to="landing"
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(0)}
          >
            <HomeIcon />
            <span>الرئيسية</span>
          </Link>
          <Link
            className="phone__item"
            to="about"
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(1)}
          >
            <ApartmentIcon />
            <span> من نحن</span>
          </Link>
          <Link
            className="phone__item"
            to="portfolio"
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(2)}
          >
            <RoofingIcon />
            <span>الخدمات</span>
          </Link>
          <Link
            className="phone__item"
            to="services"
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(3)}
          >
            <LocationIcon />
            <span> موقع المشروع</span>
          </Link>
          <Link
            className="phone__item"
            to="price"
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(4)}
          >
            <QuestionMarkIcon />
            <span>الوحدات</span>
          </Link>
          <Link
            className="phone__item"
            to="information"
            smooth={true}
            duration={500}
            onClick={() => setActiveLink(5)}
          >
            <QuestionMarkIcon />
            <span> لماذا نحن</span>
          </Link>
        </article>
      </section>
      <ProgressBar />
    </header>
  );
};

export default Header;
