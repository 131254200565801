import "./Landing.scss";
import video from "./../../assets/LandingVideo.mp4";

const Landing = () => {
  return (
    <section className="landing">
      <article className="landing__content">
        <h1>إيسترن للأستثمار العقاري</h1>
        <h4>الوجهة العقارية الأولى في الصعيد</h4>
        <img
          src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705673593/Eastern/uftke7nsyqoxfklhh2kx.png"
          alt=""
        />
      </article>
      <video muted autoPlay loop>
        <source src={video} type="video/mp4" />
      </video>
    </section>
  );
};

export default Landing;
