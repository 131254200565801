import "./About.scss";
import { Fade } from "react-awesome-reveal";

const About = () => {
  return (
    <section className="about">
      <article className="about__content">
        <article className="about__content-title">
          <Fade direction={"right"}>
            <h2>نبذه عن الشركة</h2>
          </Fade>
        </article>

        <article className="about__content-text">
          <Fade direction={"left"}>
            <p>
              إيسترن هي الشركة الرائدة في مجالات التشييد والبناء والتطويرالعقاري
              في مصر
              <br />
              حيث نجحت منذ انطلاق أعمالها عام 2020 في تطوير مشروعات استراتيجية
              كبرى في مختلف أنحاء صعيد مصر
              <br />
              حرصت الشركة دائماً علي إختيار الموقع الممتاز الذي يجذب العميل دون
              تفكير فستجد أغلبية مشروعاتها في اماكن مميزة وحيوية
              <br />
              فهي إحدى أكبر شركات العقارات في مصر والتي تتميز بخبراتها الطويلة
              داخل سوق العقارات فحرصت الشركة على تقديم مفهوماً جديداً للحياة
              السكنية مع تقديم كافة الخدمات والمرافق لتلبية إحتياجات العميل
              وحرصت دائماً على إختيار التصميمات المعمارية العصرية التي تجذب
              المستثمرين كما حرصت على إختيار الموقع الممتاز.
            </p>
          </Fade>
        </article>
      </article>
    </section>
  );
};

export default About;
