import "./Price.scss";

const Price = () => {
  return (
    <section className="price">
      <article className="price__container">
        <article className="special-heading">
          <h2>الوحدات</h2>
        </article>
        <article className="special-title">
          <h2>مقدم 10% و تقسيط حتي 8 سنوات</h2>
        </article>
        <article className="image-container">
          <article className="box">
            <h4>شقق سكنية</h4>
            <article className="box__container">
              <img
                src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-apts-1024x666.jpg"
                alt=""
              />
              <article className="caption">
                <p>تبدأ من مقدم 50% والباقي علي 10 سنوات</p>
                <button>احجز الان</button>
              </article>
            </article>
          </article>

          <article className="box">
            <h4>محلات تجارية</h4>
            <article className="box__container">
              <img
                src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-standalone-1024x576.jpg"
                alt=""
              />
              <article className="caption">
                <p>تبدأ من مقدم 50% والباقي علي 10 سنوات</p>
                <button>احجز الان</button>
              </article>
            </article>
          </article>

          <article className="box">
            <h4>خدمات فندقية</h4>
            <article className="box__container">
              <img
                src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-town-1024x666.jpg"
                alt=""
              />
              <article className="caption">
                <p>تبدأ من مقدم 50% والباقي علي 10 سنوات</p>
                <button>احجز الان</button>
              </article>
            </article>
          </article>

          <article className="box">
            <h4>بنوك</h4>
            <article className="box__container">
              <img
                src="https://madinatmasr.developmentseg.com/wp-content/uploads/2023/10/taj-twin-1024x666.jpg"
                alt=""
              />
              <article className="caption">
                <p>تبدأ من مقدم 50% والباقي علي 10 سنوات</p>
                <button>احجز الان</button>
              </article>
            </article>
          </article>
        </article>
      </article>
    </section>
  );
};

export default Price;
