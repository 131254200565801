import React, { useEffect, useState } from "react";
import "./ProgressBar.scss";

const ProgressBar = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    setScrollTop((winScroll / height) * 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div dir="ltr">
      {scrollTop > 0 && (
        <div className="progress-bar">
          <div className="fill" style={{ width: `${scrollTop}%` }}></div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
