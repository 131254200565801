import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <article className="footer__container">
        <img
          src="https://res.cloudinary.com/dmymq7zql/image/upload/v1705526256/Eastern/q2ljq91ckvomhyrlju1p.png"
          alt=""
        />
        <p>All Rights Reserved © Eastern Developments</p>
      </article>
    </footer>
  );
};

export default Footer;
